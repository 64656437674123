import Lang           from "lang.js";                   // Lang library
import data           from "resources/assets/lang.json";  // Generated language registry
import {getAppLocale} from "./globals";

/**
 * Localisation
 *
 * @var {Lang} lang
 */
let lang = new Lang({
    messages: data,
});

lang.setLocale(getAppLocale());
lang.setFallback("en");

/**
 * This is helper to translate an entire string, replacing instances of
 * {string.path} with their lang.trans values.
 *
 * TODO: Do not modify what you do not own.
 *       We should "export function format" here and import where needed.
 */
lang.format = function(string) {
    return _.replace(string, /{(.+)}/, function (match, name) {
        return lang.trans(name);
    });
};

export default lang;
